body {
	background: url("https://static.tebakgambar.com/TG-Lite/default/default-bg.png");
	background-color: #03877a;
	user-select: none;
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: 700;
}

img {
	max-width: 100%;
}

::-webkit-input-placeholder {
	text-align: center;
}

:-moz-placeholder {
	/* Firefox 18- */
	text-align: center;
}

::-moz-placeholder {
	/* Firefox 19+ */
	text-align: center;
}

:-ms-input-placeholder {
	text-align: center;
}

#wrapper {
	height: 100vh;
	position: relative;
}

#wrapper::before {
	content: '';
	position: absolute;
	bottom: 0;
	height: 60vh;
	width: 100%;
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.4));
}

#wrapper.wrapper__question::before {
	position: fixed;
	height: 45vh;
}

.dialog {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay {
	/* opacity: 0; */
	/* z-index: -1; */
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	transition: all ease .3s;
}

.overlay--show {
	/* opacity: 1;
	z-index: 10; */
}

.dialog__popup-answer {
	position: fixed;
	top: 45%;
	left: 50%;
	width: 200px;
	transform: translate(-50%, -50%);
}

.dialog__content {
	background: yellow;
	width: 85%;
	max-width: 350px;
	min-height: 125px;
	border-radius: 8px;
	text-align: center;
}

.dialog__title {
	padding: 32px 16px;
}

.dialog__hint {
	letter-spacing: 4px;
}

.dialog__share {
	background: yellow;
	max-width: 350px;
	width: 85%;
	border-radius: 8px;
	text-align: center;
}

.dialog__share--text {
	padding: 16px;
	font-style: italic;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	line-height: 1.25;
}

.dialog__share--text-title {
	margin: 16px 0;
}

.dialog__share .tg__button {
	width: 60px;
	padding: 8px;
	margin: 24px auto;
	font-size: 14px;
}

.tg__button {
	padding: 16px;
	color: white;
	background: radial-gradient(circle, transparent 1%, #f6891e 1%) center center/15000% #f6891e;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	/* -webkit-box-shadow: 3px 3px 5px 0px rgba(175,78,3,1);
	-moz-box-shadow: 3px 3px 5px 0px rgba(175,78,3,1);
	box-shadow: 3px 3px 5px 0px rgba(175,78,3,1); */
	-webkit-box-shadow: 2px 2px 1px 0px rgba(175, 78, 3, 1);
	-moz-box-shadow: 2px 2px 1px 0px rgba(175, 78, 3, 1);
	box-shadow: 2px 2px 1px 0px rgba(175, 78, 3, 1);
}

.tg__button:hover {
	cursor: pointer;
}

.tg__button:hover {
	cursor: pointer;
}

.btn--animate {
	transition: background 0.8s ease 0s;
}
.btn--animate:active {
	background-color: #f1f1f1;
	background-size: 100%;
	transition: background 0s ease 0s;
}

.tg__image {
	text-align: center;
	width: 100%;
}

.lucky-draw{
	cursor: pointer;
	height: 60px;
	margin-top: 10px;
	position: absolute;
	right: 10px;
	width: 60px;
	z-index: 1;
}

.tg__back_button {
	cursor: pointer;
	height: 60px;
	margin-top: 5px;
	position: absolute;
	left: 5px;
	width: auto;
	z-index: 1;
}

.tg__image--position {
	position: absolute;
	top: 12vh;
}

.tg__image--placeholder, .tg__image img, .tg__reload {
	width: 175px;
	min-height: 175px;
}

.tg__image--placeholder {
	margin: 0 auto;
	background: #ebebeb;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tg__image--placeholder + img {
	display: none;
}

.tg__image--placeholder::after {
	content: "Loading";
}

.tg__caption--position {
	position: absolute;
	top: 50vh;
}

.tg__caption {
	text-align: center;
	width: 100%;
	color: white;
}

.tg__already_played_text {
	position: absolute;
	top: 65vh;
}

.tg__caption p {
	width: 90%;
	max-width: 350px;
	margin: 0 auto;
	letter-spacing: 0.3px;
		line-height: 1.5;
}

.tg__action {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.tg__action--position {
	position: absolute;
	bottom: 25vh;
}
.welcome .tg__action--position {
	bottom: 22vh;
}

.tg__question {
	text-align: center;
	position: absolute;
	width: 100%;
	margin-bottom: 75px;
}

.tg__question--image {
	margin-top: 40px !important;
	width: 275px;
	min-height: 275px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tg__question--image img {
	width: auto !important;
	height: auto !important;
}

.tg__reload {
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	display: none;
	justify-content: center;
	flex-direction: column;
}

.tg__question--image .tg__image--placeholder {
	width: 275px;
	min-height: 275px;
}

.lucky__draw__page .tg__share-text {
	margin-bottom: 12px;
}

.lucky__draw__page .tg__button-container {
    margin: 8px;
}

.lucky__draw__page .tg__button {
	padding: 6px 18px;
    font-size: 11px;
    text-decoration: none;
}

/* .lucky__draw__page .tg__image--position {
	top: 10vh;
}

.lucky__draw__page .tg__caption--position {
	top: 55vh;
} */

@media (min-width: 350px) {
	.tg__question--image {
		width: 300px;
		min-height: 300px;
	}

	.tg__question--image .tg__image--placeholder {
		width: 300px;
		min-height: 300px;
	}
 }

@media (min-height: 500px) {
	.tg__image--placeholder, .tg__image img, .tg__reload {
		width: 180px;
		min-height: 180px;
	}
}

@media (min-height: 600px) {
	.tg__image--position {
		top: 10vh;
	}
	.tg__image--placeholder, .tg__image img, .tg__reload {
		width: 225px;
		min-height: 225px;
	}

	.tg__question--caption {
		margin-top: 25px;
	}
}

.tg__share {
	margin: 20px auto 0;
	text-align: center;
	font-size: 0;
}

.tg__share-icon > div {
	display: inline-block;
	vertical-align: middle;
	margin: 5px;
	border-radius: 8px;
	width: 40px;
	height: 40px;
}

.tg__question--hint {
	color: #ff0000;
	background-color: #ffff00;
	padding: 12px;
	font-size: 12px;
	margin-right: 8px;
	box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 1);
	-webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 1);
	-moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 1);
}

.tg__question-text {
	margin: auto;
  width: 150px;
	padding: 12px;
	padding-right: 10px;
	color: #ffffff;
	background: radial-gradient(circle, transparent 1%, #06a89f 1%) center center/15000% #06a89f;
	font-weight: bold;
	font-size: 12px;
}

.tg__share-text {
	display: inline-block;
	margin: auto;
  min-width: 150px;
	padding: 12px;
	padding-right: 10px;
	color: #ffffff;
	background: radial-gradient(circle, transparent 1%, #06a89f 1%) center center/15000% #06a89f;
	font-weight: bold;
	font-size: 12px;
}

.tg__share-icon:hover {
	cursor: pointer;
}

.tg__share-icon svg {
	height: 24px;
	width: 24px;
	vertical-align: middle;
}

.tg__answer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #03877a;
	height: 75px;
	display: flex;
	align-items: center;
}

.tg__answer form {
	max-width: 400px;
	margin: 0 auto;
}

.tg__answer textarea {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	vertical-align: middle;
	text-transform: uppercase;
	text-align: center;
	padding: 12px 8px;
	/* width: 60%; */
	min-width: 60%;
	min-height: 20px;
	max-height: 40px;
	max-width: 190px;
	resize: none;
	overflow-y: auto;
}

.tg__answer--button {
	display: inline-block;
	padding: 14px 20px;
}

.tg__answer--button:active {
	border-bottom-width: 2px;
}

.tg__answer--button-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.welcome .tg__share {
	bottom:8vh;
	position: absolute;
	margin: auto;
	text-align: center;
	width: 100%;
}

.tg__share-title {
	font-size: 16px;
}

.welcome__image {
	width: 200px;
	margin: 50px auto;
}

.question__caption {
	width: 80%;
	margin: 0 auto;
	max-width: 350px;
	text-align: center;
	color: white;
	/* text-shadow: 0 0 8px #000000; */
}

.ending {
	position: absolute;
	top: 10vh;
	width: 100%;
}

.ending__button {
	min-width: 150px;
	margin: 25px;
}

.welcome__button {
	min-width: 150px;
	bottom: 25vh;
}

.welcome__button:hover {
	cursor: pointer;
}

.loading {
	text-align: center;
}
.loading__caption {
	text-shadow: 0 0 4px #000000;
	color: white;
	margin-top: 25px;
	font-size: 14px;
}
.lds-ring {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border: 6px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.modal {
  display: block;
  height: 100%; 
  left: 0;
  overflow: auto; /* Enable scroll if needed */
	position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

.modal .tg__button {
	padding: 14px;
	width: 150px;
}

.modal__content {
	border-radius: 8px;
	margin: auto;
	position: absolute;
	text-align: center;
  background-color: #FDFF06;
  left: 50%;
  padding: 30px 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.modal__description {
	margin-bottom: 30px;
}

.yellow-box {
	border-radius: 8px;
	background-color: #FDFF06;
	max-width: 350px;
	width: 90%;
	margin: 0 auto;
}

.yellow-box__content {
	color: #000000;
	text-align: center;
	line-height: 1.5;
	padding: 8px 16px;
}

.tg__footer {
	bottom: 4vh;
	color: white;
	font-size: 12px;
	font-weight: normal;
	position: absolute;
	text-align: center;
	text-decoration: underline;
	width: 100%;
}

.already-played {
	position: absolute;
  left: 50%;
	top: 50%;
	width: 100%;
  -webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.already-played .tg__image--position,
.already-played .tg__caption--position,
.already-played .tg__already_played_text,
.already-played .tg__share,
.already-played .tg__footer
{
	position: static;
	top: auto;
	bottom: auto;
	margin-top: 15px;
}

.tg__reload .tg__caption {
	margin: 15px 0px;
	font-size: 14px;
}

.tg__reload .tg__button {
	display: inline-block;
	font-size: 14px;
	font-weight: normal;
	padding: 14px;
	width: 100px;
}
